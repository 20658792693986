import React, { useMemo, useState, useEffect, useCallback } from 'react';
import './App.css'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { MaterialReactTable } from 'material-react-table';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  CircularProgress,
  Typography,
  Paper,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
} from '@mui/material';
import { AttachMoney } from '@mui/icons-material';
import { supabase } from './components/supabase.js';
import DetailPanel from './components/DetailPanel';
import AdvancedSearch from './components/AdvancedSearch';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { FilterList } from '@mui/icons-material';
import { FaTelegramPlane } from 'react-icons/fa'; // Telegram
import { SiSignal } from 'react-icons/si'; // Signal
import { FaSnapchatGhost } from 'react-icons/fa'; // Snapchat

let theme;

let websiteName, telegram, signal, snapchat, logo, websiteAnon;

websiteAnon = process.env.REACT_APP_WEBSITE_ANON;

if (websiteAnon === '1') {
  websiteName = process.env.REACT_APP_WEBSITE_ANON_NAME;
  telegram = process.env.REACT_APP_SOCIAL_ANON_TELEGRAM;
  signal = process.env.REACT_APP_SOCIAL_ANON_SIGNAL;
  snapchat = process.env.REACT_APP_SOCIAL_ANON_SNAPCHAT;
  logo = process.env.REACT_APP_ANON_LOGO;
} else {
  websiteName = process.env.REACT_APP_WEBSITE_NAME;
  telegram = process.env.REACT_APP_SOCIAL_TELEGRAM;
  signal = process.env.REACT_APP_SOCIAL_SIGNAL;
  snapchat = process.env.REACT_APP_SOCIAL_SNAPCHAT;
  logo = process.env.REACT_APP_LOGO;
}
switch (websiteAnon) {
  case '0':
    theme = createTheme({
      palette: {
        primary: {
          main: '#023047',
          light: '#FFF',
          dark: '#023047',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#023047',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#023047',
          secondary: '#023047',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#023047',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#023047',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root:{
              fontSize: '0.875rem'
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: `
            .social-link {
              text-decoration: none; /* Supprime le soulignement */
              color: inherit;       /* Utilise la couleur du texte par défaut */
              transition: color 0.3s ease; /* Présente une transition douce */
            }
            .social-link:hover {
              color: #FFB703; /* Couleur de surbrillance au survol */
            }
          `,
        },
      },
    });
    break;

  case '1':
    theme = createTheme({
      palette: {
        primary: {
          main: '#000',
          light: '#FFF',
          dark: '#000',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#000',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#000',
          secondary: '#000',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#000',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#000',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root:{
              fontSize: '0.875rem'
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: `
              .social-link {
                text-decoration: none; /* Supprime le soulignement */
                color: inherit;       /* Utilise la couleur du texte par défaut */
                transition: color 0.3s ease; /* Présente une transition douce */
              }
              .social-link:hover {
                color: #FFB703; /* Couleur de surbrillance au survol */
              }
            `,
        },
      },
    });
    break;

  default:
    theme = createTheme({
      palette: {
        primary: {
          main: '#023047',
          light: '#FFF',
          dark: '#023047',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#023047',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#023047',
          secondary: '#023047',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#023047',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#023047',
            },
          },
        },
        MuiFormLabel: {
          styleOverrides: {
            root:{
              fontSize: '0.875rem'
            }
          }
        },
        MuiCssBaseline: {
          styleOverrides: `
            .social-link {
              text-decoration: none; /* Supprime le soulignement */
              color: inherit;       /* Utilise la couleur du texte par défaut */
              transition: color 0.3s ease; /* Présente une transition douce */
            }
            .social-link:hover {
              color: #FFB703; /* Couleur de surbrillance au survol */
            }
          `,
        },
      },
    });
    break;
}

const fetchPack = async () => {
  const { data } = await supabase
    .from('pack')
    .select(
      `
      *,
      customers (
        *,
        pay (
          mois,
          annee,
          salaire
        ),
        tax (
          revenu,
          annee_impots,
          situation,
          domiciliation,
          parts,
          autre_doc,
          pdf
        ),
        identity (
          id,
          type_doc,
          qualite,
          noiretblanc,
          pdf,
          birthdate,
          expiry_date,
          race,
          country,
          "4_bords_visibles",
          civilite,
          autre_doc
        )
      )
    `,
    )
    .in('statut', ['Actif', 'Vendu']);

  const customersWithBankNames = await Promise.all(
    data.map(async (packItem) => {
      const customer = packItem.customers[0];
      if (customer && customer.code_banque) {
        const { data: bankData, error: bankError } = await supabase
          .from('banque')
          .select('Banque')
          .eq('Code_Banque', customer.code_banque)
          .single();

        if (bankError) {
          customer.nom_banque = 'N/A';
        } else {
          customer.nom_banque = bankData.Banque;
        }
      } else {
        customer.nom_banque = 'N/A';
      }
      return packItem;
    }),
  );

  return customersWithBankNames;
};

const currentYear = new Date().getFullYear();

const moisNoms = [
  'Jan',
  'Fév',
  'Mar',
  'Avr',
  'Mai',
  'Juin',
  'Juil',
  'Août',
  'Sep',
  'Oct',
  'Nov',
  'Déc',
];

const App = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  // const [averageSalary, setAverageSalary] = useState(0);
  const [loading, setLoading] = useState(true);
  const [expandedRow, setExpandedRow] = useState(null);
  const [countries, setCountries] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchPack();

      setData(fetchedData);
      setFilteredData(fetchedData);
      setLoading(false);

      const distinctCountries = await fetchDistinctCountries();
      setCountries(distinctCountries);

      document.title = websiteName || '';
    };
    loadData();
  }, []);

  const handleAdvancedSearch = useCallback((searchParams) => {
    const filtered = data.filter((pack) => {
      // Vérifiez chaque paramètre de recherche et retournez true si tous les paramètres correspondent
      if (
        searchParams.typeDoc &&
        !pack.customers?.some((customer) =>
          customer.identity?.some((id) => id.type_doc === searchParams.typeDoc),
        )
      ) {
        return false;
      }

      if (
        searchParams.typeContrat &&
        !pack.customers?.some((customer) =>
          pack.customers?.some((id) => id.contrat_travail === searchParams.typeContrat),
        )
      ) {
        return false;
      }

      if (
        searchParams.civilite &&
        !pack.customers?.some((customer) =>
          customer.identity?.some((id) => id.civilite === searchParams.civilite),
        )
      ) {
        return false;
      }
      if (
        searchParams.country &&
        !pack.customers?.some((customer) =>
          customer.identity?.some((id) =>
            id.country?.toLowerCase().includes(searchParams.country.toLowerCase()),
          ),
        )
      ) {
        return false;
      }
      if (
        searchParams.pdf_identity &&
        !pack.customers?.some((customer) => customer.identity?.some((id) => id.pdf === true))
      ) {
        return false;
      }
      if (
        !searchParams.pdf_identity &&
        !pack.customers?.some((customer) =>
          customer.identity?.some((id) => id.pdf === false || id.pdf === true),
        )
      ) {
        return false;
      }
      if (
        searchParams.pdf_impots &&
        !pack.customers?.some((customer) => customer.tax?.some((id) => id.pdf === true))
      ) {
        return false;
      }
      if (
        !searchParams.pdf_impots &&
        !pack.customers?.some((customer) =>
          customer.tax?.some((id) => id.pdf === false || id.pdf === true),
        )
      ) {
        return false;
      }
      if (
        searchParams.pay_pdf &&
        !pack.customers?.some((customer) => pack.customers?.some((id) => id.pay_pdf === true))
      ) {
        return false;
      }
      if (
        !searchParams.pay_pdf &&
        !pack.customers?.some((customer) =>
          pack.customers?.some((id) => id.pay_pdf === false || id.pay_pdf === true),
        )
      ) {
        return false;
      }
      if (
        searchParams.typesPersonne.Noir &&
        !pack.customers.some((customer) => customer.identity?.some((id) => id.race === 'Noir'))
      ) {
        return false;
      }
      if (
        searchParams.typesPersonne.Arabe &&
        !pack.customers.some((customer) => customer.identity?.some((id) => id.race === 'Arabe'))
      ) {
        return false;
      }

      // Filtre pour l'année de naissance
      if (searchParams.birthYearFrom) {
        const birthYearFrom = parseInt(searchParams.birthYearFrom);
        // Si searchParams.birthYearTo n'est pas renseigné ou est supérieur ou égal à la valeur minimale
        if (
          !pack.customers?.some((customer) =>
            customer.identity?.some((id) => {
              const birthYear = parseInt(id.birthdate);
              return birthYear >= birthYearFrom; // Vérifiez uniquement la valeur minimale
            }),
          )
        ) {
          return false;
        }
      }

      if (searchParams.birthYearTo) {
        const birthYearTo = parseInt(searchParams.birthYearTo);
        if (
          !pack.customers?.some((customer) =>
            customer.identity?.some((id) => {
              const birthYear = parseInt(id.birthdate);
              return birthYear <= birthYearTo; // Vérifiez uniquement la valeur maximale
            }),
          )
        ) {
          return false;
        }
      }

      // Filtre pour le revenu imposable
      if (searchParams.revenueFrom) {
        const revenueFrom = parseFloat(searchParams.revenueFrom);
        // Vérifiez uniquement la valeur minimale
        if (
          !pack.customers?.some((customer) =>
            customer.tax?.some((t) => {
              return t.revenu >= revenueFrom; // Vérifiez la condition avec la valeur minimale
            }),
          )
        ) {
          return false;
        }
      }

      if (searchParams.revenueTo) {
        const revenueTo = parseFloat(searchParams.revenueTo);
        if (
          !pack.customers?.some((customer) =>
            customer.tax?.some((t) => {
              return t.revenu <= revenueTo; // Vérifiez la condition avec la valeur maximale
            }),
          )
        ) {
          return false;
        }
      }

      // Filtre pour le nombre de parts fiscales
      if (searchParams.partsCount) {
        // Remplace les virgules par des points
        const partsCountInput = searchParams.partsCount.toString().replace(',', '.');
        const partsCount = parseFloat(partsCountInput);

        // Vérifiez que le nombre de parts soit un nombre valide
        if (!isNaN(partsCount)) {
          if (
            !pack.customers?.some((customer) =>
              customer.tax?.some((t) => {
                return t.parts === partsCount; // Comparaison directe de la valeur flottante
              }),
            )
          ) {
            return false;
          }
        }
      }

      if (searchParams.domiciliation && searchParams.domiciliation.length > 0) {
        const hasDomiciliation = pack.customers?.some((customer) =>
          customer.tax?.some((t) => {
            return searchParams.domiciliation.includes(t.domiciliation); // Comparez avec le nombre
          }),
        );

        if (!hasDomiciliation) {
          return false;
        }
      }

      // Filtre pour l'ancienneté
      if (
        searchParams.anciennete &&
        !pack.customers?.some((customer) =>
          pack.customers?.some((id) => id.anciennete === searchParams.anciennete),
        )
      ) {
        return false;
      }

      if (searchParams.hasRib) {
        if (!pack.customers?.some((customer) => customer.has_iban === true)) {
          return false;
        }
      }
      if (searchParams.hasQRCode) {
        if (!pack.customers?.some((customer) => customer.qrcode_fdp === true)) {
          return false;
        }
      }

      return true;
    });

    setFilteredData(filtered);
    setDrawerOpen(false);
    
  }, [data]);

const handleReset = useCallback(() => {
  setFilteredData(data); // Réinitialise les données filtrées aux données d'origine
  setDrawerOpen(false);
}, [data]); // Référence à data pour réinitialiser correctement

  const fetchDistinctCountries = async () => {
    const { data, error } = await supabase
      .from('identity')
      .select('country', { count: 'exact' })
      .not('country', 'is', null)
      .neq('country', '');

    if (error) {
      console.error('Erreur:', error);
      return [];
    }

    const countries = [...new Set(data.map((item) => item.country))];
    return countries;
  };

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        {
          accessorFn: (row) => row.pack_id,
          id: 'pack_id',
          header: 'Pack',
          enableClickToCopy: true,
          enableColumnFilter: false,
          Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
          size: 20,
        },
        {
          accessorFn: (row) => row.statut,
          id: 'statut',
          header: 'Statut',
          enableColumnFilter: false,
          size: 50,
          enableSorting: false,
          Cell: ({ cell }) => {
            const value = cell.getValue();
            switch (value) {
              case 'Actif':
                return 'Dispo';
              case 'Vendu':
                return 'Vendu';
              default:
                return 'N/A';
            }
          },
        },
        {
          accessorFn: (row) => {
            const basePrice = row.price;
            return websiteAnon === '1' ? basePrice + 100 : basePrice;
          },
          id: 'price',
          header: 'Prix',
          size: 50,
          Cell: ({ cell }) =>
            cell.getValue() ? (
              <Typography
                variant='body2'
                sx={{ fontWeight: 'bold', color: theme.palette.secondary.dark }}
              >
                {cell.getValue().toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Typography>
            ) : (
              'N/A'
            ),
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {
            marks: true,
            step: 20,
            valueLabelFormat: (value) =>
              value.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }),
          },
          Filter: ({ column }) => (
            <TextField
              type='number'
              onChange={(e) => {
                const value = e.target.value;
                column.setFilterValue(value ? [value] : undefined);
              }}
              placeholder='Prix min'
              variant='outlined'
              size='small'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
      ];
    } else {
      return [
        {
          accessorFn: (row) => row.pack_id,
          id: 'pack_id',
          header: 'Pack',
          enableClickToCopy: true,
          Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong>,
        },
        {
          accessorFn: (row) => row.statut,
          id: 'statut',
          header: 'Statut',
          enableColumnFilter: false,
          enableSorting: false,
          Cell: ({ cell }) => {
            const value = cell.getValue();
            switch (value) {
              case 'Actif':
                return 'Disponible';
              case 'Vendu':
                return 'Vendu';
              default:
                return 'N/A';
            }
          },
        },
        {
          accessorFn: (row) => {
            const basePrice = row.price;
            return websiteAnon === '1' ? basePrice + 100 : basePrice;
          },
          id: 'price',
          header: 'Prix',
          Cell: ({ cell }) =>
            cell.getValue() ? (
              <Typography
                variant='body2'
                sx={{ fontWeight: 'bold', color: theme.palette.secondary.dark }}
              >
                {cell.getValue().toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Typography>
            ) : (
              'N/A'
            ),
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {
            marks: true,
            step: 20,
            valueLabelFormat: (value) =>
              value.toLocaleString('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }),
          },
          Filter: ({ column }) => (
            <TextField
              type='number'
              onChange={(e) => {
                const value = e.target.value;
                column.setFilterValue(value ? [value] : undefined);
              }}
              placeholder='Prix min'
              variant='outlined'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
        {
          accessorFn: (row) => {
            return (
              row.customers
                ?.flatMap((customer) =>
                  customer.pay
                    ?.slice(0, 3)
                    .sort((a, b) => {
                      const moisA = parseInt(a.mois);
                      const moisB = parseInt(b.mois);
                      return moisA - moisB;
                    })
                    .map((pay) => ({
                      id: pay.id, // ajouter un identifiant unique ici si disponible
                      salaire: pay.salaire,
                      mois: pay.mois,
                      annee: pay.annee !== null ? pay.annee : currentYear, // Remplace null par l'année en cours
                    })),
                )
                .filter((pay) => pay.salaire != null) || null
            );
          },
          id: 'salaires',
          header: 'Salaires',
          Cell: ({ cell }) => {
            const salaires = cell.getValue();
            if (!salaires) return 'N/A';
            return salaires.map((pay, index) => (
              <div key={`${pay.annee}-${pay.mois}-${index}`}>
                {' '}
                {/* Utilise une clé unique combinée */}
                {`${moisNoms[parseInt(pay.mois) - 1]} ${pay.annee}: `}
                {pay.salaire.toLocaleString('fr-FR', {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            ));
          },
          sortingFn: (rowA, rowB, columnId) => {
            const salairesA = rowA.getValue(columnId);
            const salairesB = rowB.getValue(columnId);

            const getMaxSalaire = (salaires) => {
              if (!salaires) return 0;
              return Math.max(...salaires.map((pay) => pay.salaire));
            };

            const maxSalaireA = getMaxSalaire(salairesA);
            const maxSalaireB = getMaxSalaire(salairesB);

            return maxSalaireA - maxSalaireB;
          },
          filterFn: (row, columnId, filterValue) => {
            const salaires = row.getValue(columnId);
            if (!salaires) return false;
            const minSalaire = filterValue?.[0];
            if (minSalaire === undefined || minSalaire === '') return true;
            const minSalaireNum = parseFloat(minSalaire);
            return salaires.some((pay) => pay.salaire >= minSalaireNum);
          },
          Filter: ({ column }) => (
            <TextField
              type='number'
              onChange={(e) => {
                const value = e.target.value;
                column.setFilterValue(value ? [value] : undefined);
              }}
              placeholder='Salaire min'
              variant='outlined'
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AttachMoney />
                  </InputAdornment>
                ),
              }}
            />
          ),
        },
      ];
    }
  }, [isMobile]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ overflow: 'auto', height: '100vh' }}>
        <AppBar position='static'>
          <Toolbar>
            {(() => {
              switch (websiteAnon) {
                case '0':
                  return (
                    <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                      {websiteName}
                    </Typography>
                  );
                case '1':
                  return (
                    <Typography component='div' sx={{ flexGrow: 1 }}>
                      <img
                        src={logo}
                        alt='Logo'
                        style={{
                          filter:
                            'invert(95%) sepia(97%) saturate(14%) hue-rotate(213deg) brightness(104%) contrast(104%)',
                        }}
                        className="logo"
                      />
                    </Typography>
                  );
                default:
                  return null; // Renvoyez un composant par défaut ou rien
              }
            })()}
            {isMobile ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: 'auto',
                  }}
                >
                  {telegram && (
                    <a
                      href={telegram}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='social-link'
                      style={{ display: 'flex', alignItems: 'center' }} // Assure l'alignement vertical
                    >
                      <FaTelegramPlane
                        style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.2em' }}
                      />
                    </a>
                  )}
                  {signal && (
                    <a
                      href={signal}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='social-link'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <SiSignal
                        style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.2em' }}
                      />
                    </a>
                  )}
                  {snapchat && (
                    <a
                      href={snapchat}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='social-link'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <FaSnapchatGhost
                        style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.2em' }}
                      />
                    </a>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 'auto',
                  }}
                >
                  <IconButton
                    color='inherit'
                    aria-label='Filter'
                    edge='end'
                    onClick={() => setDrawerOpen(true)}
                    sx={{ display: 'flex', alignItems: 'center' }} // Assure l'alignement vertical
                  >
                    <Typography variant='body1' sx={{ mr: 1, lineHeight: '1.5em' }}>
                      Filtrer
                    </Typography>
                    <FilterList />
                  </IconButton>
                </Box>
              </Box>
            ) : (
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end' }}>
                {telegram && (
                  <a
                    href={telegram}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='social-link'
                  >
                    <FaTelegramPlane
                      style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.5em' }}
                    />
                  </a>
                )}
                {signal && (
                  <a
                    href={signal}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='social-link'
                  >
                    <SiSignal style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.5em' }} />
                  </a>
                )}
                {snapchat && (
                  <a
                    href={snapchat}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='social-link'
                  >
                    <FaSnapchatGhost
                      style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1.5em' }}
                    />
                  </a>
                )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
        {isMobile ? (
          <Drawer
            anchor='right'
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            ModalProps={{
              keepMounted: true, // Pour améliorer les performances de l'ouverture du Drawer
            }}
            sx={{
              '& .MuiDrawer-paper': {
                width: isMobile ? '90%' : 300,
                height: isMobile ? '100%' : 'auto',
              },
            }}
          >
            <Box sx={{ padding: 2 }} role='presentation'>
              <AdvancedSearch
                onSearch={handleAdvancedSearch}
                onReset={handleReset}
                countries={countries}
              />
            </Box>
          </Drawer>
        ) : (
          <AdvancedSearch
            onSearch={handleAdvancedSearch}
            onReset={handleReset}
            countries={countries}
            sx={{ mt: 4, mb: 4 }}
          />
        )}
        {loading ? (
          <Box display='flex' justifyContent='space-around' alignItems='center' minHeight='70vh'>
            <CircularProgress />
          </Box>
        ) : (
          <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
            <MaterialReactTable
              columns={columns}
              data={filteredData}
              enableGlobalFilter={false}
              enableFacetedValues
              enableToolbarInternalActions={false}
              enableBottomToolbar={true}
              globalFilterModeOptions={['contains', 'fuzzy', 'advanced']}
              enableColumnActions={false}
              
              initialState={{
                density: 'compact',
                showColumnFilters: true,
                pagination: {
                  pageSize: 50,
                  pageIndex: 0
                },
                sorting: [
                  {
                    id: 'pack_id',
                    desc: true, // false pour ordre ascendant, true pour ordre descendant
                  },
                ],
                columnOrder: [
                  'mrt-row-expand',
                  'pack_id',
                  ...columns.filter((col) => col.id !== 'pack_id').map((col) => col.id),
                ],
              }}
              muiPaginationProps={{
                rowsPerPageOptions: [10, 25, 50, 100],
                showFirstButton: false,
                showLastButton: false
              }}
              
              enableExpandAll={false}
              positionPagination='both'
              
              muiTableContainerProps={{
                sx: { maxHeight: '100%', width: '100%', overflowX: 'auto'},
              }}
              muiTablePaperProps={{
                elevation: 0,
                sx: {
                  borderRadius: '0',
                  overflowX: 'auto', // Assure le défilement horizontal
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  fontWeight: 'bold',
                  backgroundColor: theme.palette.primary.light,
                  color: theme.palette.primary.dark,
                  padding: 'Orem'
                },
              }}
              muiDetailPanelProps={{
                sx: (theme) => ({
                  backgroundColor:
                    theme.palette.mode === 'dark'
                      ? 'rgba(142, 202, 230, 0.1)'
                      : 'rgba(142, 202, 230, 0.1)',
                  borderRadius: '0 0 4px 4px',
                }),
              }}
              muiExpandAllButtonProps={({ table }) => ({
                sx: {
                  transform: table.getIsSomeRowsExpanded() ? 'rotate(-90deg)' : 'rotate(180deg)',
                  transition: 'transform 0.2s',
                  color: theme.palette.primary.main,
                },
              })}
              muiExpandButtonProps={({ row }) => ({
                onClick: () => setExpandedRow(expandedRow === row.id ? null : row.id),
                sx: {
                  transform: expandedRow === row.id ? 'rotate(180deg)' : 'rotate(-90deg)',
                  transition: 'transform 0.2s',
                  color: theme.palette.primary.main,
                },
              })}
              renderDetailPanel={({ row }) => <DetailPanel row={row} />}
              getRowId={(row) => row.pack_id}
              localization={{
                actions: 'Actions',
                and: 'et',
                cancel: 'Annuler',
                changeFilterMode: 'Changer le mode de filtre',
                changeSearchMode: 'Changer le mode de recherche',
                clearFilter: 'Effacer le filtre',
                clearSearch: 'Effacer la recherche',
                clearSort: 'Effacer le tri',
                clickToCopy: 'Cliquer pour copier',
                collapse: 'Réduire',
                collapseAll: 'Tout réduire',
                columnActions: 'Actions de colonne',
                copiedToClipboard: 'Copié dans le presse-papier',
                dropToGroupBy: 'Déposer pour grouper par {column}',
                edit: 'Modifier',
                expand: isMobile ? '' : 'Développer',
                expandAll: 'Tout développer',
                filterArrIncludes: 'Inclut',
                filterArrIncludesAll: 'Inclut tous',
                filterArrIncludesSome: 'Inclut certains',
                filterBetween: 'Entre',
                filterBetweenInclusive: 'Entre (inclus)',
                filterByColumn: 'Filtrer par {column}',
                filterContains: 'Contient',
                filterEmpty: 'Vide',
                filterEndsWith: 'Se termine par',
                filterEquals: 'Égal à',
                filterEqualsString: 'Égal à',
                filterFuzzy: 'Flou',
                filterGreaterThan: 'Supérieur à',
                filterGreaterThanOrEqualTo: 'Supérieur ou égal à',
                filterInNumberRange: 'Entre',
                filterIncludesString: 'Contient',
                filterIncludesStringSensitive: 'Contient (sensible à la casse)',
                filterLessThan: 'Inférieur à',
                filterLessThanOrEqualTo: 'Inférieur ou égal à',
                filterMode: 'Mode de filtre: {filterType}',
                filterNotEmpty: 'Non vide',
                filterNotEquals: 'Différent de',
                filterStartsWith: 'Commence par',
                filterWeakEquals: 'Égal à',
                filteringByColumn: 'Filtrage par {column} - {filterType} {filterValue}',
                goToFirstPage: 'Aller à la première page',
                goToLastPage: 'Aller à la dernière page',
                goToNextPage: 'Aller à la page suivante',
                goToPreviousPage: 'Aller à la page précédente',
                grab: 'Saisir',
                groupByColumn: 'Grouper par {column}',
                groupedBy: 'Groupé par ',
                hideAll: 'Masquer tout',
                hideColumn: 'Masquer la colonne {column}',
                max: 'Max',
                min: 'Min',
                move: 'Déplacer',
                noRecordsToDisplay: 'Aucun enregistrement à afficher',
                noResultsFound: 'Aucun résultat trouvé',
                of: 'sur',
                or: 'ou',
                pinToLeft: 'Épingler à gauche',
                pinToRight: 'Épingler à droite',
                resetColumnSize: 'Réinitialiser la taille de la colonne',
                resetOrder: "Réinitialiser l'ordre",
                rowActions: 'Actions de ligne',
                rowNumber: '#',
                rowNumbers: 'Numéros de ligne',
                rowsPerPage: 'Lignes par page',
                save: 'Enregistrer',
                search: 'Rechercher',
                select: 'Sélectionner',
                selectedCountOfRowCountRowsSelected:
                  '{selectedCount} sur {rowCount} ligne(s) sélectionnée(s)',
                showAll: 'Afficher tout',
                showAllColumns: 'Afficher toutes les colonnes',
                showHideColumns: 'Afficher/Masquer les colonnes',
                showHideFilters: 'Afficher/Masquer les filtres',
                showHideSearch: 'Afficher/Masquer la recherche',
                sortByColumnAsc: 'Trier par {column} croissant',
                sortByColumnDesc: 'Trier par {column} décroissant',
                thenBy: ', puis par ',
                toggleDensity: 'Changer la densité',
                toggleFullScreen: 'Basculer en plein écran',
                toggleSelectAll: 'Tout sélectionner',
                toggleSelectRow: 'Sélectionner la ligne',
                toggleVisibility: 'Basculer la visibilité',
                ungroupByColumn: 'Dégrouper par {column}',
                unpin: 'Détacher',
                unpinAll: 'Tout détacher',
                unsorted: 'Non trié',
              }}
            />
          </Paper>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default App;
