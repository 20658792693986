// ViewPack.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, CircularProgress, Paper } from '@mui/material';
import { supabase } from './supabase.js'; // Importation de Supabase
import DetailPanel from './DetailPanel'; // Import de DetailPanel
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

let theme;

const websiteAnon = process.env.REACT_APP_WEBSITE_ANON;

switch (websiteAnon) {
  case '0':
    theme = createTheme({
      palette: {
        primary: {
          main: '#023047',
          light: '#FFF',
          dark: '#023047',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#023047',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#023047',
          secondary: '#023047',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#023047',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#023047',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
            .social-link {
              text-decoration: none; /* Supprime le soulignement */
              color: inherit;       /* Utilise la couleur du texte par défaut */
              transition: color 0.3s ease; /* Présente une transition douce */
            }
            .social-link:hover {
              color: #FFB703; /* Couleur de surbrillance au survol */
            }
          `,
        },
      },
    });
    break;

  case '1':
    theme = createTheme({
      palette: {
        primary: {
          main: '#000',
          light: '#FFF',
          dark: '#000',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#000',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#000',
          secondary: '#000',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#000',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#000',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
            .social-link {
              text-decoration: none; /* Supprime le soulignement */
              color: inherit;       /* Utilise la couleur du texte par défaut */
              transition: color 0.3s ease; /* Présente une transition douce */
            }
            .social-link:hover {
              color: #FFB703; /* Couleur de surbrillance au survol */
            }
          `,
        },
      },
    });
    break;

  default:
    theme = createTheme({
      palette: {
        primary: {
          main: '#023047',
          light: '#FFF',
          dark: '#023047',
          contrastText: '#FFFFFF',
        },
        secondary: {
          main: '#FFB703',
          dark: '#FB8500',
          contrastText: '#023047',
        },
        background: {
          default: '#F5F5F5',
          paper: '#FFFFFF',
        },
        text: {
          primary: '#023047',
          secondary: '#023047',
        },
      },
      typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
          fontWeight: 600,
          color: '#023047',
        },
        h6: {
          fontWeight: 500,
        },
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: '#023047',
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: `
            .social-link {
              text-decoration: none; /* Supprime le soulignement */
              color: inherit;       /* Utilise la couleur du texte par défaut */
              transition: color 0.3s ease; /* Présente une transition douce */
            }
            .social-link:hover {
              color: #FFB703; /* Couleur de surbrillance au survol */
            }
          `,
        },
      },
    });
    break;
}


const ViewPack = () => {
  const { pack_id } = useParams(); // Récupération de pack_id depuis l'URL
  const navigate = useNavigate(); // Hook pour la navigation
  const [data, setData] = useState(null); // État pour stocker les données du pack
  const [loading, setLoading] = useState(true); // État pour gérer le chargement

  useEffect(() => {
    const fetchPackData = async () => {
      const { data: packData, error } = await supabase
        .from('pack')
        .select(`
          *,
          customers (
            *,
            pay (
              mois,
              annee,
              salaire
            ),
            tax (
              revenu,
              annee_impots,
              situation,
              domiciliation,
              parts,
              autre_doc,
              pdf
            ),
            identity (
              id,
              type_doc,
              qualite,
              noiretblanc,
              pdf,
              birthdate,
              expiry_date,
              race,
              country,
              "4_bords_visibles",
              civilite,
              autre_doc
            )
          )
        `) // Requête correcte pour charger les données nécessaires
        .eq('pack_id', pack_id) // Filtre pour pack_id
        .single(); // Récupération d'un seul enregistrement

      if (error) {
        console.error('Erreur lors du chargement des données :', error);
        setLoading(false);
      } else {
        const customersWithBankNames = await Promise.all(
          packData.customers.map(async (customer) => {
            if (customer && customer.code_banque) {
              const { data: bankData, error: bankError } = await supabase
                .from('banque')
                .select('Banque')
                .eq('Code_Banque', customer.code_banque)
                .single();
    
              if (bankError) {
                customer.nom_banque = 'N/A';
              } else {
                customer.nom_banque = bankData.Banque;
              }
            } else {
              customer.nom_banque = 'N/A';
            }
            return customer;
          }),
        );
    
        // Ajoutez le tableau des clients mis à jour au packData
        packData.customers = customersWithBankNames;
        
        setData(packData); // Stockage des données du pack
        setLoading(false);
      }
    };

    fetchPackData(); // Appel de la fonction pour charger les données
  }, [pack_id]); // Dépendance sur pack_id

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return <Typography variant='h6'>Aucune donnée trouvée</Typography>;
  }

  // Remplacez 'Actif' par 'Disponible'
  const statut = data.statut === 'Actif' ? 'Disponible' : data.statut;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
    <Box>
    <AppBar position="static">
  <Toolbar sx={{ 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    padding: '8px'
  }}>
    <Button 
      color="warning" 
      onClick={() => navigate('/')}
      sx={{ 
        flexShrink: 0, // Empêche le bouton de rétrécir
        mr: { xs: 1, sm: 2 } // Marge à droite, plus grande sur desktop
      }}
    >
      Retour
    </Button>
    <Typography 
      variant="h6" 
      sx={{ 
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap', // Empêche le texte de passer à la ligne
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Ajoute des points de suspension si le texte est trop long
        maxWidth: { xs: 'calc(100% - 240px)', sm: 'calc(100% - 300px)' } // Ajuste la largeur maximale
      }}
    >
      Dossier {data.pack_id}
    </Typography>
  </Toolbar>
</AppBar>
      <Paper elevation={3} sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Statut: {statut} {/* Affichage du statut */}
        </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
    Prix: {data.price ? (websiteAnon === '1' ? (data.price + 100) : data.price).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR'
      }) : 'N/A'} {/* Affichage du prix */}
  </Typography>
        <DetailPanel row={{ original: data }} />
      </Paper>
    </Box>
    </ThemeProvider>
  );
};

export default ViewPack;
